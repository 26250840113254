/**
 * A noop padding strategy.
 */
export const NoPadding = {
  pad() {
  },

  unpad() {
  },
};
